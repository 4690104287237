




























import { Component, Prop, Vue } from 'vue-property-decorator'

// store
import { ReviewResource } from '@/store/types'

@Component
export default class ReviewCard extends Vue {
  @Prop({ default: '' })
  private link!: string

  @Prop({ default: null })
  private review!: ReviewResource | null

  @Prop({ default: true })
  private readonly!: boolean

  // Контент отзыва без html тегов
  private get content() {
    return this.review && this.review.content? this.review.content.replace(/<p>/g, '').replace(/<\/?[^>]+>/g, '') : ''
  }

  // Обрезанный контент отзыва до 150 символов
  private get shortContent() {
    return this.content.length > 149 ? `${this.content.slice(0, 149)} ... ` : this.content
  }

  // Имя автора отзыва
  private get authorName() {
    if (this.review) {
      return this.review.name && this.review.surname ? `${this.review.name} ${this.review.surname}` : this.review.name && !this.review.surname ? this.review.name : ''
    }
    return ''
  }

  private get authorAvatar() {
    return this.review?.avatar?.url ?? this.avatarPlaceholder
  }

  private noPreview = require('@/assets/images/banner/review-no-preview.jpg')
  private avatarPlaceholder = require('@/assets/images/banner/review-no-photo.jpg')

  private showReview() {
    this.$emit('showReview', this.review)
  }
}
