






























import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import vuescroll from 'vuescroll'

// components
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import YoutubeIframeNative from '@/components/YoutubeIframeNative.vue'
// store
import { ReviewResource } from '@/store/types'

@Component({
  components: {
    Dialog,
    ModalWrapper,
    YoutubeIframeNative,
  },
})
export default class ReviewModal extends Vue {
  @Ref() scroll!: vuescroll

  @Prop({ default: false })
  private arrows!: boolean

  @Prop({ default: null })
  private review!: ReviewResource | null

  @Prop({ default: false })
  private visible!: boolean

  // Имя автора отзыва
  private get authorName() {
    if (this.review) {
      return this.review.name && this.review.surname ? `${this.review.name} ${this.review.surname}` : this.review.name && !this.review.surname ? this.review.name : ''
    }
    return ''
  }

  private get heightContent() {
    return this.heightWindow * 0.9 - this.heightHeader
  }

  private noPhoto = require('@/assets/images/banner/review-no-photo.jpg')

  private heightWindow = window.innerHeight
  private heightHeader = 0
  private heightWrapperContent = 0

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
    vuescroll: {
      sizeStrategy: 'number',
    },
  }

  private mounted() {
    this.$nextTick(() => {
      this.getHeight()
      this.scroll?.refresh()
      window.addEventListener('resize', this.getHeight)
    })
  }

  private destroyed() {
    window.removeEventListener('resize', this.getHeight)
    this.heightHeader = 0
  }

  private getHeight() {
    const header = document.querySelector('.review-modal-header')
    const content = document.querySelector('.review-modal-content-wrapper')
    this.heightHeader = (header as HTMLDivElement)?.offsetHeight || 0
    this.heightWrapperContent = (content as HTMLDivElement)?.offsetHeight || 0
    this.heightWindow = window.innerHeight
  }

  private handleClose() {
    this.$emit('update:visible', false)
    this.$emit('closeModal')
  }

  @Watch('review')
  private watchReview() {
    this.$nextTick(() => {
      this.getHeight()
      this.scroll?.refresh()
    })
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    if (!value) {
      this.heightHeader = 0
    } else {
      this.$nextTick(() => {
        this.getHeight()
        this.scroll?.refresh()
      })
    }
  }
}
